<template lang="pug">
span
  confirmation-dialog(
    v-model="showing"
    @okBtnClicked="$emit('submit')"
    v-if="editable"
    width="420px"
    title='Edit course'
    descr='Are you sure you want to edit the course? It may affect the students'
  )
  app-button(color="primary" @click.native="submit") {{ btnText}}

</template>

<script>
import CourseDayValidator from '../../../classes/curssussen/courseDayValidator'
import showingMixin from '@/mixins/showing.mixin'
export default {
  mixins: [showingMixin],
  props: {
    editable: Boolean,
    candidatesCount: Number,
    btnText: String,
    formErrors: Object,
    formData: Object
  },

  methods: {
    submit() {
      this.formErrors.resetFields()
      if (!this.validate()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      if(this.editable && this.candidatesCount > 0)  {
        this.updateShowing(true)
        return
      }
      this.$emit('submit')
      
    },

    validate() {
      let valid = new CourseDayValidator(this.formData, this.formErrors)
      return valid.isValid()
    },
  },

  components: {
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>

<style lang="scss">
</style>