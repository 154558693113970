import {REQUIRED} from '@/util/validation-rules';

export default class CMExamConfigValidator {
  constructor(data, validator) {
    this.data = data;
    this.validator = validator;
  }

  validate() {
    this.validator.resetFields();
    return this.isValid();
  }

  isValid() {
    this.validateLocation();
    // this.validateMaxStudents();
    this.validateMeetingSpace();
    this.validateDate();
    this.validateSessionConfig();

    return this.validator.isValid();
  }

  validateLocation() {
    if (!this.validateRequired('city', this.data.city));
  }

  validateMaxStudents() {
    if (!this.validateRequired('max_students', this.data.max_students));
  }

  validateMeetingSpace() {
    if (this.data.meeting_space === -1) return;
    if (!this.data.meeting_space.id) return this.validator.invalidField('meeting_space', 'Required');
    if (!this.validateRequired('meeting_space', this.data.meeting_space));
  }

  validateDate(field, date) {
    if (!this.validateRequired('date', this.data.date));
  }

  validateSessionConfig() {
    let errors = {};
    if (!this.data.course_day_sections.length) return this.validator.invalidField('sessions', 'Required');

    this.data.course_day_sections.forEach((config, index) => {
      if(!this.data.course_day_sections[index].session) {
        errors.session = 'Required.';
      }
      let fullErrors = [];
      if(this.validator.fields.course_day_sections) fullErrors = this.validator.fields.course_day_sections;
      fullErrors[index] = errors;
      if(Object.keys(errors).length) this.validator.invalidField('course_day_sections', fullErrors);
      errors = {}
    });
  }

  validateTime(val) {
    return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val);
  }

  isValidTime(val, field, validator) {
    if(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val)) return true;
    validator.invalidField(field, 'Not correct time');
  }

  transportExamStartGreatherExamFinish(_timeFrom, _timeTo) {
    if (!_timeTo || !_timeFrom) return false;
    let {timeTo, timeFrom} = this.getFormattedTime(_timeFrom, _timeTo);
    if (timeFrom > timeTo) return true;
    return false;
  }

  getFormattedTime(_timeFrom, _timeTo) {
    let timeFrom = new Date();
    let timeTo = new Date();
    timeFrom.setHours(..._timeFrom.split(':'));
    timeTo.setHours(..._timeTo.split(':'));
    return {
      timeFrom: timeFrom,
      timeTo: timeTo
    };
  }

  validateRequired(field, data) {
    let match = REQUIRED(data);
    if (match === true) return true;
    this.validator.invalidField(field, match);
    return false;
  }
}
